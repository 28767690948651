<template>
    <!-- group: { selector: 'maChuyenDi', desc: false }, -->
    <DxList
        :data-source="{
            store: DanhSachChuyenDi,
        }"
        class="bao-cao-ban-ve"
        height="100%"
        :hoverStateEnabled="false"
        :focusStateEnabled="false"
        :activeStateEnabled="false"
    >
        <template #item="{ data: item }">
            <ItemVeVue :item="item" />
        </template>
        <!-- :collapsible-groups="true"
        :grouped="true"
        <template #group="{ data: item }">
            <div class="font-16 font-medium" @click="onItemClick(item)">
                {{ item.key ? $Helper.formatDate(item.key) : "Ngày" }}
            </div>
        </template> -->
    </DxList>
</template>

<script>
import { DxList } from "devextreme-vue";
import ItemVeVue from "./ItemVe.vue";
export default {
    components: {
        DxList,
        ItemVeVue,
    },
    data() {
        return {
            DanhSachChuyenDi: [],
        };
    },
    computed: {},
    methods: {
        ToastThongBao(type, msg) {
            let toast = {
                type: type,
                message: msg,
                visible: true,
            };
            this.emitter.emit("onToast", toast);
        },
        async LayDanhSachVe() {
            try {
                this.DanhSachChuyenDi = (
                    await this.$store.dispatch("BaoCao/Get_BaoCaoNhanVienBanVe")
                ).Store;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
    },
    mounted() {
        let self = this;
        let arrEvent = this.emitter.all;
        for (const [key, value] of arrEvent) {
            if (key == "LayDanhSachVe") {
                this.emitter.off("LayDanhSachVe");
            }
        }
        this.emitter.on("LayDanhSachVe", () => {
            this.LayDanhSachVe();
        });
    },
};
</script>

<style >
.bao-cao-ban-ve .dx-item.dx-list-item {
    overflow: unset;
    margin-bottom: 12px;
}
.bao-cao-ban-ve .dx-list-group-body {
    max-height: calc(100vh - 36px - 36px - 48px - 44px - 40px);
    overflow: auto;
}
.bao-cao-ban-ve .dx-list-group-header {
    color: #fb8c00;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-direction: row-reverse;
    padding: 8px 8px 4px;
    border: 1px solid #dadce0;
    border-radius: 4px;
}
</style>