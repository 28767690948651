<template>
    <div class="frame-chuyen-di">
        <div class="pt-2 pb-2 px-2">
            <div class="row justify-center font-20 font-medium mb-1">
                {{ item.maChuyenDi }}
            </div>

            <div
                class="row align-center mb-2"
                style="border-bottom: 1px solid #dadce0; padding-bottom: 4px"
            >
                <div class="xs4">
                    <div
                        class="row font-14 font-medium justify-center"
                        style="white-space: break-spaces; text-align: center"
                    >
                        {{ item.diemDi || "(Trống)" }}
                    </div>
                </div>
                <div class="xs4 ke-ngang-tuyen">
                    <div class="row justify-center">
                        <i
                            class="mdi mdi-circle-slice-8 font-16 color-success"
                        ></i
                        >------------<i
                            class="mdi mdi-circle-slice-8 font-16 color-error"
                        ></i>
                    </div>
                </div>
                <div class="xs4">
                    <div
                        class="row font-14 font-medium justify-center"
                        style="white-space: break-spaces; text-align: center"
                    >
                        {{ item.diemDen || "(Trống)" }}
                    </div>
                </div>
            </div>

            <div :class="rowText">
                <div class="grow">
                    <div class="row">
                        <div class="text-left">Thời gian bán:</div>
                        <div :class="textInfo">
                            {{ $Helper.formatDateTime(item.thoiGianBan) }}
                        </div>
                    </div>
                </div>
                <div class="shrink font-medium font-16 color-success">
                    {{ $MoneyFormat(item.thanhTien) }}đ
                </div>
            </div>
            <div :class="rowText">
                <div class="text-left">Ký hiệu:</div>
                <div :class="textInfo">{{ item.kyHieu }}</div>
            </div>
            <div :class="rowText">
                <div class="text-left">Số hóa đơn:</div>
                <div :class="textInfo">{{ item.soHoaDon }}</div>
            </div>
            <div :class="rowText">
                <div class="text-left">Người mua:</div>
                <div
                    :class="textInfo"
                    style="width: 70%; white-space: break-spaces"
                >
                    {{ item.hoTenHanhKhach || "(Trống)" }}
                </div>
            </div>
            <div :class="rowText">
                <div class="text-left">Số điện thoại:</div>
                <div :class="textInfo">
                    {{ item.soDienThoai || "(Trống)" }}
                </div>
            </div>
        </div>

        <div style="position: absolute; bottom: 8px; right: 8px">
            <DxButton
                icon="mdi mdi-printer-outline"
                type="normal"
                styling-mode="text"
                @click="layThongTinVe(item.maDatCho)"
            />
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
export default {
    components: {
        DxButton,
    },
    props: {
        item: { type: Object, default: () => {} },
    },
    data() {
        return {
            rowText: "row mb-1 align-center ",
            textInfo: "grow font-medium ml-1",
        };
    },
    methods: {
        ToastThongBao(type, msg) {
            let modelToast = {
                isVisible: true,
                message: msg,
                type: type,
            };
            this.emitter.emit("onToast", modelToast);
        },
        async layThongTinVe(maDatCho) {
            try {
                this.$startLoading;
                let rs = await this.$AppClass.InVe(
                    this.$i18n.t("url.InVeTheoMaDatCho"),
                    "MaDatCho",
                    maDatCho
                );
                this.$stopLoading;
                if (rs == "error") {
                    return this.ToastThongBao("error", "In vé thất bại! ");
                } else {
                    return this.ToastThongBao("success", "In vé thành công! ");
                }
            } catch (error) {
                this.$stopLoading;
                this.error = true;
                console.error(error);
            }
        },
    },
};
</script>

<style scoped>
.frame-chuyen-di {
    box-shadow: 0px 1px 5px #dadce0;
    border: 1px solid #dadce0;
    margin-top: 8px;
    border-radius: 8px;
    padding: unset !important;
}
.text-left {
    width: 92px;
}
.wrap-text {
    white-space: break-spaces;
}
</style>